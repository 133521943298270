@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.cdnfonts.com/css/satoshi');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

body,
html,
* {
  margin: 0;
  padding: 0;
}

.font-satoshi {
  font-family: 'Satoshi', sans-serif;
}

.font-dmsans {
  font-family: 'DM Sans', sans-serif;
}

.teamname {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(13.5px);
  border-radius: 7px;
}
.bg-avatar {
  background-position: center top;
  border-radius: 28px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-avatar1 {
  background-image: url('assets/avatar1.png');
}

.bg-avatar2 {
  background-image: url('assets/avatar2.png');
}

.bg-hero {
  background-image: url('assets/hero.svg');
}

.bg-productImg {
  background-image: url('assets/productImg.svg');
}

.bg-productImg2 {
  background-image: url('assets/productImg2.svg');
}

.bg-hero-product {
  background-image: url('assets/hero2.svg');
}

.bg-hero-product2 {
  background-image: url('assets/hero3.svg');
}

.bg-\[background-position-x\:-150px\] {
  background-position-x: -150px;
}

.bg-\[background-position-y\:-60px\] {
  background-position-y: -60px;
}

.custom-clip-path {
  clip-path: polygon(0 55%, 68% 44%, 100% 100%, 0% 100%);
  background: #0b1b35;
  color: white;
}

.bg-clip-path {
  clip-path: polygon(0 16%, 100% 0, 100% 100%, 0% 100%);
  background: linear-gradient(#fd9463, #ea6565);
}

.custom-box-shadow {
  box-shadow: 0px 2.3977649211883545px 1.9182120561599731px 0px
      rgba(158, 158, 158, 0.02),
    0px 5.762162208557129px 4.609730243682861px 0px rgba(158, 158, 158, 0.03),
    0px 10.849640846252441px 8.679712295532227px 0px rgba(158, 158, 158, 0.04),
    0px 19.353904724121094px 15.483123779296875px 0px rgba(158, 158, 158, 0.04),
    0px 36.1993522644043px 28.959482192993164px 0px rgba(158, 158, 158, 0.05),
    0px 86.64773559570312px 69.31818389892578px 0px rgba(158, 158, 158, 0.07);
}

.myswiperbox .swiper-button-prev {
  top: 10% !important;
  left: 89% !important;
}

.myswiperbox .swiper-button-next {
  top: 10% !important;
  right: 5% !important;
}

.myswiperbox .swiper-button-next:after,
.myswiperbox .swiper-rtl .swiper-button-prev:after,
.myswiperbox .swiper-button-prev:after,
.myswiperbox .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}

.myswiperbox .swiper-horizontal > .swiper-pagination-bullets,
.myswiperbox .swiper-pagination-bullets.swiper-pagination-horizontal,
.myswiperbox .swiper-pagination-custom,
.myswiperbox .swiper-pagination-fraction {
  bottom: auto !important;
}

/* Mobile */
@media (max-width: 800px) {
  .myswiperbox .swiper-button-prev {
    top: 10% !important;
    left: 88% !important;
  }

  .myswiperbox .swiper-button-next {
    top: 10% !important;
    right: 5% !important;
  }
}

/* Mobile */
@media (max-width: 450px) {
  .myswiperbox .swiper-button-prev {
    left: 82% !important;
  }
}

@media (max-width: 900px) {
  .bg-\[background-position-x\:-150px\] {
    background-position-x: 0;
  }

  .bg-\[background-position-y\:-60px\] {
    background-position-y: 0px;
  }
}
